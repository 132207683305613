<template>
    <div class="view">
        <div v-if='loading || !orderInfo' class="loadingBox">
            <van-loading type="spinner" color="#1989fa" vertical>加载中...</van-loading>
        </div>
        <van-pull-refresh v-model="refreshLoading" @refresh="refresh" v-else>
            <div class="orderInfo">
                <div class="title">
                    <div class="status">{{ orderInfo.status | filterStatus }}</div>
                    <div class="callPhone" @click="callPhone(1)">
                        <van-icon name="phone-o" size='16'/>
                        <span>致电平台</span>
                    </div>
                </div>
                <div class="line"></div>
                <div class="order">
                    <div class="order_title" @click="order_upIcon = !order_upIcon">
                        <span>订单信息</span>
                        <van-icon name="arrow-down" :class=" order_upIcon ? 'up' : 'down' " />
                    </div>
                    <div v-if='order_upIcon'>
                        <div class="order_line"></div>
                        <div class="info_li">
                            <div>
                                <span>订单号码： </span>
                                <span>{{ orderInfo.no }}</span>
                            </div>
                            <div class="callPhone" @click="onCopy">
                                <van-icon name="records" size='12'/>
                                <span>复制</span>
                            </div>
                        </div>
                        <div class="info_li" v-if='$statusRule(1, orderInfo)'>
                            <div>
                                <span>预约时间： </span>
                                <span v-if='orderInfo.is_subscribe == 2'>
                                    {{ orderInfo.appoint_time | filterTime }}
                                </span>
                                <span v-else>及时到达</span>
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li" v-if='$statusRule(1, orderInfo)'>
                            <div>
                                <span>车主信息： </span>
                                <span>{{ orderInfo.contact | filterContact }}** </span>
                                <span> {{ orderInfo.phone | filterUserPhone }}****</span>
                            </div>
                            <div 
                                class="callPhone" 
                                @click="callPhone(2)" 
                                v-if="$statusRule(1, orderInfo)">
                                <van-icon name="phone-o" size='12'/>
                                <span>致电客户</span>
                            </div>
                        </div>
                        <div class="info_li" v-if='!$statusRule(1, orderInfo)'>
                            <div>
                                <span>救援类型： </span>
                                <span>{{ orderInfo.type | filterType }}</span>
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li" v-if='!$statusRule(1, orderInfo)'>
                            <div>
                                <span>故障车牌： </span>
                                <span>{{ orderInfo.car_no }}</span>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
                <div class="order" v-if='$statusRule(1, orderInfo)'>
                    <div class="order_title" @click="rescue_upIcon = !rescue_upIcon">
                        <span>救援信息</span>
                        <van-icon name="arrow-down" :class=" rescue_upIcon ? 'up' : 'down' " />
                    </div>
                    <div v-if='rescue_upIcon'>
                        <div class="order_line"></div>
                        <div class="info_li">
                            <div>
                                <span>救援类型： </span>
                                <span>{{ orderInfo.type | filterType }}</span>
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li">
                            <div>
                                <span>故障车牌： </span>
                                <span>{{ orderInfo.car_no }}</span>
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li">
                            <div>
                                <span>品牌型号： </span>
                                <span>{{ orderInfo.car_model ? orderInfo.car_model : '-' }}</span>
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li">
                            <div>
                                <span>车架号： </span>
                                <span>{{ orderInfo.vin ? orderInfo.vin : '-' }}</span>
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li">
                            <div>
                                <span>免拖公里数： </span>
                                <span>{{ orderInfo.free_milage ? orderInfo.free_milage : '0.0' }}</span>
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li">
                            <div>
                                故障车位置：
                            </div>
                            <div class="callPhone" @click="openMap(1)" v-if='$statusRule(1, orderInfo)'>
                                <van-icon name="location-o" size='12'/>
                                <span>导航前往故障车位置</span>
                            </div>
                        </div>
                        <div class="info_li address">
                            <div class="address_text">
                                {{ orderInfo.address ? orderInfo.address : '-' }}
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li" v-if="isDoubleAddress">
                            <div>
                                拖车目的地：
                            </div>
                            <div class="callPhone" @click="openMap(2)" v-if='$statusRule(1, orderInfo)'>
                                <van-icon name="location-o" size='12'/>
                                <span>导航前往拖车目的地</span>
                            </div>
                        </div>
                        <div class="info_li address" v-if="isDoubleAddress">
                            <div class="address_text">
                                {{ orderInfo.destination ? orderInfo.destination : '-' }}
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
                <div class="order" v-if='$statusRule(1, orderInfo)'>
                    <div class="order_title">
                        <span>
                            现场照片
                            {{ sitePhotos.length != 0 ? `（共${sitePhotos.length}张）` : '' }}
                        </span>
                    </div>
                    <div class="siteImgs">
                        <div class="order_line"></div>
                        <van-uploader 
                            v-if="sitePhotos.length != 0"
                            upload-text='现场照片'
                            :show-upload='false'
                            :deletable='false'
                            v-model="sitePhotos"/>
                        <van-empty
                            v-else
                            class="custom-image"
                            image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
                            description="无现场照片，请联系客户上传"/>
                    </div>
                </div>
            </div>
        </van-pull-refresh>
        <div class="footer" v-if="orderInfo">
            <div class="btn" @click="downloadAPP">前往APP接单</div>
        </div>
        <van-dialog 
            v-model="service_info_dialog"
            title='服务说明'
            :message='orderInfo.service_info'
            messageAlign='left'
            confirmButtonText='已阅读并同意'>
        </van-dialog>
    </div>
</template>

<script>
import Data from '@/utils/data.js';
import { getLink, orderDetail, uploadPicture } from '@/apis/checkList';
import { getQueryString, formatTime, validLngLat } from '@/utils/index';
export default {
    data(){
        return {
            order: '',
            loading: false,
            refreshLoading: false,
            orderInfo: '',
            order_upIcon: true,
            rescue_upIcon: true,
            sitePhotos: [],
            service_info_dialog: false,
            isDoubleAddress: false
        }
    },
    created(){
        // let params = {
        //     order_id: '11083',
        //     type: 'driver'        //owner 车主链接 driver 技师链接
        // }
        // getLink(params).then((res)=>{
        //     console.log(res, '获取案件链接');
        //     // 测试加密参数         TzEwOTU3    TzExMDc4    TzExMDgz
        // })
        this.order = getQueryString();
        this.loading = true;
        this.getDetail();
    },
    methods: {
        refresh(){
            // 下拉刷新
            console.log('下拉刷新')
            this.getDetail();
        },
        getDetail(){
            let params = {
                order: this.order
            };
            orderDetail(params).then((res)=>{
                console.log(res, '订单详情')
                this.orderInfo = res.data.data;
                this.isDoubleAddress = this.$jyTypeData(this.orderInfo.type, 'address') == 2
                document.title = this.orderInfo.firm.name;
                console.log(this.orderInfo, '订单详情');
                if(this.loading && this.orderInfo.service_info){
                    this.service_info_dialog = true;
                };
                let picture = this.orderInfo.picture;
                this.sitePhotos = [];
                if(picture && picture.length != 0){
                    picture.forEach((item)=>{
                        if(item.key == 'customer'){
                            let obj = {
                                id: item.id,
                                url: item.doman + item.picture
                            };
                            this.sitePhotos.push(obj);
                        }
                    })
                }
                this.loading = false;
                this.refreshLoading = false;
            })
        },
        // 打电话
        callPhone(index){
            let tel;
            if(index == 1){
                // 平台service_phone
                let service_phone = this.orderInfo.firm.service_phone;
                if(service_phone){
                    tel = `tel:${service_phone}`;
                    window.location.href = tel;
                }else{
                    this.$toast('联系电话错误！');
                    return
                }
            }else{
                // 客户
                let mobile = this.orderInfo.phone;
                if(mobile){
                    tel = `tel:${mobile}`;
                    window.location.href = tel;
                }else{
                    this.$toast('联系电话错误！');
                    return
                }
            }
        },
        // 复制订单号
        onCopy(){
            let text = this.orderInfo.no;
            this.$copyText(text).then(()=>{
                this.$toast('订单号复制成功');
            }).catch(()=>{
                this.$toast('复制失败');
            })
        },
        // 打开地图导航
        openMap(index){
            let url;
            if(index == 1){
                // 救援地theodolitic
                if(!validLngLat(this.orderInfo.theodolitic)){
                    this.$toast('故障车位置异常，请联系车主!');
                    return
                }
                let name = this.orderInfo.address;
                url = `http://uri.amap.com/marker?position=${this.orderInfo.theodolitic}&name=${name}&coordinate=gaode&callnative=1`;
            }else if(index == 2){
                // 目的地transit
                if(!validLngLat(this.orderInfo.transit)){
                    this.$toast('目的地位置异常，请联系车主!');
                    return
                }
                let name = this.orderInfo.destination;
                url = `http://uri.amap.com/marker?position=${this.orderInfo.transit}&name=${name}&coordinate=gaode&callnative=1`;
            }
            url && window.open(url);
        },
        // 下载商家APP
        downloadAPP(){
            this.$router.push({
                path: '/app',
                query: {
                    type: 1
                }
            })
        },
    },
    filters: {
        filterStatus(val){
            if(val || val == 0){
				let orderStatus = Data.orderStatus;
				return orderStatus[val];
			}else{
				return '-'
			}
        },
        filterTime(val){
			if(val){
				return formatTime(val)
			}
		},
        filterContact(val){
            if(val){
                return val.substr(0,1);
            }
        },
        filterUserPhone(val){
            if(val){
                let reg = /^(\d+)(\d{7})$/;
                let tel = val.toString().replace(reg, "$1***");
                return tel
            }
        },
    }
}
</script>

<style lang='scss'>
    .siteImgs{
        .van-uploader__preview, .van-uploader__upload {
            width: 160px;
            height: 120px;
            margin: 10px 0 0 0;
            .van-image{
                width: 160px;
                height: 120px;
            }
        }
        .van-uploader{
            width: 100%;
        }
        .van-uploader__wrapper{
            justify-content: space-between;
        }
        .custom-image .van-empty__image {
            width: 80px;
            height: 80px;
        }
    }
</style>

<style lang="scss" scoped>
    .view{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        .loadingBox{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .orderInfo{
            min-height: 100%;
            padding: 15px 15px 100px;
            box-sizing: border-box;
            .title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                font-weight: bold;
                .callPhone{
                    padding: 4px;
                    box-sizing: border-box;
                    border-radius: 2px;
                    display: flex;
                    align-items: center;
                    background: var(--theme-color);
                    color: #fff;
                    span{
                        margin-left: 6px;
                    }
                }
            }
            .line{
                border-bottom: 1px solid #EFEFEF;
				margin-top: 15px;
            }
            .order_line{
                border-bottom: 1px solid #EFEFEF;
				margin-top: 10px;
            }
            .order{
                margin-top: 15px;
                font-size: 14px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                background: rgba(255, 255, 255, 0.39);
                border-radius: 4px;
                padding: 15px 10px;
                box-sizing: border-box;
                .order_title{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    span{
                        font-weight: bold;
                    }
                    .up{
                        transition: transform 0.3s;
                        transform: rotateZ(180deg);
                    }
                    .down{
                        transition: transform 0.3s;
                        transform: rotateZ(0deg);
                    }
                }
                .info_li{
                    font-size: 14px;
                    margin-top: 18px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #141414;
                    .callPhone{
                        padding: 3px 4px;
                        box-sizing: border-box;
                        border-radius: 2px;
                        display: flex;
                        align-items: center;
                        background: var(--theme-color);
                        color: #fff;
                        span{
                            margin-left: 6px;
                        }
                    }
                }
                .address{
                    margin-top: 10px;
                    z-index: 9;
                    .address_text{
                        line-height: 20px;
                    }
                }
            }
        }
        .footer{
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            .btn{
                width: 300px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                border-radius: 6px;
                background: var(--theme-color);
                color: #fff;
            }
        }
    }
</style>
